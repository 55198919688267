export const enum Keys {
  businessId = 'izs_businessId',
  izsBirthdate = 'izs_initial',
  ppzBusinessId = 'zahn_ppz_otr_businessId',
}

/**
 * Manage the storage configuration
 */
export const storage = {
  get(key: Keys) {
    return sessionStorage.getItem(key);
  },
  set(key: Keys, value: string) {
    sessionStorage.setItem(key, value);
  },
  remove(key: Keys) {
    sessionStorage.removeItem(key);
  },
  clear() {
    sessionStorage.clear();
  },
  /**
   * Store the neccesary values and remove the keys not needed.
   * Currently we store:
   * - Keys.businessId (from Zahn OTR)
   * - Keys.izsBirthdate
   * - Keys.ppzBusinessId
   */
  normalize() {
    const keysToSave = [Keys.businessId, Keys.izsBirthdate, Keys.ppzBusinessId];

    const storageBackup = {} as Record<Keys, string>;

    keysToSave.forEach((key) => {
      const storedValue = storage.get(key);
      if (storedValue) {
        storageBackup[key] = storedValue;
      }
    });

    storage.clear();

    Object.entries(storageBackup).forEach(([key, value]) => {
      storage.set(key as Keys, value);
    });
  },
};
