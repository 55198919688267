import { BirthdayDateInputInterface, ValueInterface } from 'components/Birthday/birthday.types';
import config from 'config/config';
import { Keys, storage } from 'helpers';

export const isDataExpired = (
  value: string,
  from = new Date(),
  maxNumOfDaysToExpireData = config.birthdayMaxNumOfDaysToExpireData
): boolean => {
  const date = new Date(value);
  const daysDiff: number = (from.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
  return daysDiff > maxNumOfDaysToExpireData;
};

export const getInitialBirthday = () => {
  const data = storage.get(Keys.izsBirthdate);
  if (data) {
    const { birthday, date } = JSON.parse(data);
    if (date && !isDataExpired(date)) {
      const [year, month, day] = birthday.split('-');
      return {
        year,
        month,
        day,
      };
    }
  }

  return {};
};

export const formatBirthDateToValue = (birthDate: string): ValueInterface => {
  const [year, month, day] = birthDate.split('-');

  return {
    year,
    month,
    day,
  };
};

export const birthdateValueToFormat = (value: ValueInterface) => {
  const { year, month, day } = value;

  return `${year}-${month}-${day}`;
};

export const isValidBirthdayDateInput = (birthdayDateInput: BirthdayDateInputInterface) => {
  const { errors, value } = birthdayDateInput;
  if (value.day?.length !== 2 || value.month?.length !== 2 || value.year?.length !== 4) {
    return false;
  }

  if (errors && errors.errorMessage) {
    return false;
  }

  return true;
};
