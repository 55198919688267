import { useEffect, useState } from 'react';
import Modal from '@eg/elements/Modal';
import LayoutBox from '@eg/elements/LayoutBox';
import { LoaderIcon } from '@eg/elements/components/Icons';
import cx from 'classnames';
import { useAppSelector, useAppDispatch } from 'hooks/hooks';
import { popupClose, selectChoiceType, selectPopup } from 'stores/uiStore';
import { IDefProps } from 'interfaces';
import { ChoiceTypes } from 'helpers/enums';
import getTxt from 'helpers/getTxt';
import config from 'config/config';
import { AemWindow } from 'helpers/interfaces';
import './compareServicesModal.scss';

export const loadExternalJs = (content: any): string => {
  const aemWindow = window as AemWindow;
  if (aemWindow.loadFetchedAemJs) {
    return aemWindow.loadFetchedAemJs(content);
  }
  return content;
};

const getProductTablePath = (choiceType?: string) => {
  const { params } = window as AemWindow;
  const key = params && Object.keys(params).filter((k) => k.includes(config.aemAppId))[0];
  if (key && choiceType) {
    const { productTableKeyTeeth, productTableKeyDentures } = config;
    const path =
      choiceType === ChoiceTypes.dentures
        ? params[key][productTableKeyDentures]
        : params[key][productTableKeyTeeth];
    return `${window.location.origin}${path}`;
  }
};

const CompareServicesModal: React.FC<IDefProps> = ({ parentId }) => {
  const dispatch = useAppDispatch();
  const isPopupOpened = useAppSelector(selectPopup);
  const choiceType = useAppSelector(selectChoiceType);
  const popupHandleDissmis = () => dispatch(popupClose());
  const [tableSrc, setTableSrc] = useState<string | undefined>();
  const [isLoading, setLoaded] = useState(true);

  const iframeClassNames = cx('izs_product-iframe', {
    'izs_product-iframe__hidden': isLoading,
  });

  useEffect(() => {
    if (isPopupOpened) {
      const productTableUrl = (window as AemWindow)?.params && getProductTablePath(choiceType);
      setTableSrc(productTableUrl);
    }

    if (!isPopupOpened) {
      setTableSrc('');
    }
  }, [isPopupOpened]);

  return (
    <Modal
      id={`${parentId}_modal`}
      data-testid={`${parentId}_modal`}
      open={isPopupOpened}
      onDismiss={popupHandleDissmis}
      extendedWidth
      dismissible
      backdropDismissesModal>
      {!!(window as AemWindow)?.params ? (
        <>
          {isLoading ? (
            <LayoutBox
              horizontalAlignment='center'
              verticalAlignment='center'
              className='izs_spinner-box'>
              <LoaderIcon />
            </LayoutBox>
          ) : null}
          <iframe className={iframeClassNames} src={tableSrc} onLoad={() => setLoaded(false)} />
        </>
      ) : (
        getTxt('template.modalContent')
      )}
    </Modal>
  );
};

export default CompareServicesModal;
