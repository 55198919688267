// why typescript is off for this file?
// read this:
// https://github.com/microsoft/TypeScript/issues/1897

// eslint-disable-next-line
// @ts-nocheck
import translations from 'config/txtFlatten.json';

const getText = (translationId: any): string => {
  if (!translations[translationId]) {
    console.error(`[getText] translation problem, missing id: ${translationId}`);
    return '';
  }
  return translations[translationId];
};

export default getText;
