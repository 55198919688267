import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { storageMiddleware } from './middlewares';
import uiReducer from './uiStore';
import userDataReducer from './userDataStore';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    userData: userDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(storageMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
