import Choicer from 'components/Choicer/Choicer';
import getTxt from 'helpers/getTxt';
import { useAppSelector } from 'hooks/hooks';
import { ChoiceTypes, DataLayerIds, Options } from 'helpers/enums';
import { urlParamTariff1, urlParamTariff2 } from 'stores/userDataStore';
import { IDefProps } from 'interfaces';
import {
  choicer1Data,
  choicer1Descriptions,
  choicer2Data,
  choicer2Descriptions,
} from './defaultData';
import Tooltip from 'components/Tooltip/Tooltip';
import './multipleChoice.scss';

const createUrlParams = (multiplieChoiceOptions: { teeth: Options; dentures: Options }) => {
  window.location.hash =
    urlParamTariff1 +
    (multiplieChoiceOptions.teeth !== Options.KS ? multiplieChoiceOptions.teeth : 'None') +
    urlParamTariff2 +
    (multiplieChoiceOptions.dentures !== Options.KS ? multiplieChoiceOptions.dentures : 'None');
};

const MultipleChoice: React.FC<IDefProps> = ({ parentId }) => {
  const { multiplieChoiceOptions } = useAppSelector(({ userData }) => userData);
  createUrlParams(multiplieChoiceOptions);

  const identifier = `${parentId}_multiple-choice`;

  return (
    <div className='esc_grid multiple-choice__container' id={`${identifier}_container`}>
      <div className='esc_grid__wrapper'>
        <div className='esc_col esc_col-12 esc_col-l-6 esc_col-m-6'>
          <h4 className='multiple-choice__header' id={`${identifier}_${ChoiceTypes.teeth}_header`}>
            {getTxt('multipleChoice.header1')}
            <Tooltip
              eventTrackingId={DataLayerIds.IZS_InfoIcon_Zahnerhalt}
              descriptionPopup={getTxt('multipleChoice.header1Tooltip')}
              className='multiple-choice__tooltip-text'
            />
          </h4>
          <p
            className='multiple-choice__subheader'
            id={`${identifier}_${ChoiceTypes.teeth}_subheader`}>
            {getTxt('multipleChoice.subheader1')}
          </p>
          <Choicer
            parentId={identifier}
            type={ChoiceTypes.teeth}
            choices={choicer1Data}
            descriptions={choicer1Descriptions}
            id={DataLayerIds.IZS_Link_ZahnerhaltLeistungenVergleichen}
            compareServicesText={getTxt('multipleChoice.compareServices1')}
          />
        </div>
        <div className='esc_col esc_col-12 esc_col-l-6 esc_col-m-6'>
          <h4
            className='multiple-choice__header'
            id={`${identifier}_${ChoiceTypes.dentures}_header`}>
            {getTxt('multipleChoice.header2')}
            <Tooltip
              eventTrackingId={DataLayerIds.IZS_InfoIcon_Zahnersatz}
              descriptionPopup={getTxt('multipleChoice.header2Tooltip')}
              className='multiple-choice__tooltip-text'
            />
          </h4>
          <p
            className='multiple-choice__subheader'
            id={`${identifier}_${ChoiceTypes.dentures}_subheader`}>
            {getTxt('multipleChoice.subheader2')}
          </p>
          <Choicer
            parentId={identifier}
            type={ChoiceTypes.dentures}
            choices={choicer2Data}
            descriptions={choicer2Descriptions}
            id={DataLayerIds.IZS_Link_ZahnersatzLeistungenVergleichen}
            compareServicesText={getTxt('multipleChoice.compareServices2')}
          />
        </div>
      </div>
    </div>
  );
};
export default MultipleChoice;
