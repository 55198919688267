import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from 'stores/store';
import { Provider } from 'react-redux';
import { Provider as EgProvider, THEME } from '@eg/elements/Provider';
import * as serviceWorker from './serviceWorker';
import 'sass/index.scss';

const eeStyle = process.env.REACT_APP_ERGO_ELEMENTS_STYLE || 'ergo-one';

/* eslint-disable import/first */
require(`@eg/elements/styles/${eeStyle}/styles.css`);
require(`@eg/elements/styles/core.css`);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <EgProvider theme={eeStyle as THEME}>
        <App />
      </EgProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('ppz-izs-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
