import LoadingSpinner from '@eg/elements/LoadingSpinner';
import cx from 'classnames';
import { getChoiceSelectionsText, getPriceValue } from 'components/Price/PriceDetails';
import getTxt from 'helpers/getTxt';
import { useAppSelector } from 'hooks/hooks';
import { Loading } from 'helpers/enums';
import { IDefProps } from 'interfaces';
import './stickyHeader.scss';
import { useEffect, useState } from 'react';
import { selectUserData } from 'stores/userDataStore';

const StickyHeaderPrice = () => {
  const { multiplieChoiceOptions, prices } = useAppSelector(selectUserData);
  if (prices.status === Loading.loading) {
    return (
      <div className='sticky-header__spinner-wrapper'>
        <LoadingSpinner viewport='relative' show={true} id='stickyHeaderSpinner' />
      </div>
    );
  }
  if (!!prices.values) {
    return (
      <div className='sticky-header__wrapper'>
        {multiplieChoiceOptions.dentures !== 'KS' && (
          <div className='sticky-header__text'>
            {getTxt('price.from')} {getTxt('price.fromMonth')}
            &nbsp;je&nbsp;
            {getPriceValue(false, multiplieChoiceOptions, prices)}
            &nbsp;&euro;*
          </div>
        )}
        <div>
          <span className='sticky-header__price'>
            {getPriceValue(true, multiplieChoiceOptions, prices)}
            &nbsp;&euro;
          </span>
          <span className='sticky-header__mtl'>{getTxt('price.mtl')}</span>
        </div>
      </div>
    );
  }
  return null;
};

const StickyHeader: React.FC<IDefProps> = ({ parentId }) => {
  const {
    birthday,
    multiplieChoiceOptions: { teeth, dentures },
  } = useAppSelector(selectUserData);
  const [stickyHeaderVisible, updateStickyHeaderVisibility] = useState(false);
  const finalClassnamesHeader = cx('sticky-header', {
    'sticky-header--active': stickyHeaderVisible && birthday !== null,
    'sticky-header--replaced': window.document.querySelector('#MainNavi') !== null,
  });
  const identifier = `${parentId}_mobileHeader`;

  useEffect(() => {
    const documentSelector = document.querySelector('.izs-app-container');
    if (documentSelector) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          updateStickyHeaderVisibility(entry.isIntersecting);
        },
        { threshold: 0 }
      );
      observer.observe(documentSelector);
    }
  }, []);
  return (
    <div className={finalClassnamesHeader} id={identifier}>
      <div className='esc_h5 sticky-header__esc_h5'>{getTxt('price.mobile.header')}</div>
      <div>{getChoiceSelectionsText(teeth, dentures)} </div>
      <div>
        <StickyHeaderPrice />
      </div>
    </div>
  );
};
export default StickyHeader;
