import { KeyboardEvent, useState, useEffect, useRef } from 'react';
import getTxt from 'helpers/getTxt';
import Button from '@eg/elements/Button';
import ControlWithHint from '@eg/elements/ControlWithHint';
import DateInput from '@eg/elements/DateInput';
import { asDate } from '@eg/elements/utils/date';
import { useAppDispatch } from 'hooks/hooks';
import {
  getFees,
  selectUserData,
  updateBirthday,
  updateOffer,
  updatePricesStatus,
} from 'stores/userDataStore';
import { useAppSelector } from 'hooks/hooks';

import { IDefProps } from 'interfaces';
import useTracking from 'dataLayerAPI/useTracking';
import './birthday.scss';
import {
  Keys,
  storage,
  ElementsToScrollEnum,
  handleScrollToElement,
  DataLayerIds,
  Loading,
} from 'helpers';
import { useBirthday } from './useBirthday';
import { OfferChangeParams, ValueInterface } from './birthday.types';
import { getInitialBirthday } from 'stores/userDataStore.helpers';

const today = new Date();
const minDate = new Date(today.getFullYear() - 99, today.getMonth(), today.getDate() + 1);

const Birthday: React.FC<IDefProps> = ({ parentId }) => {
  const dispatch = useAppDispatch();
  const { birthdayDateInput, setBirthdayDateInput } = useBirthday();
  const [offerChangeParams, setOfferChangeParams] = useState<OfferChangeParams | null>(null);
  const requestTimeout = useRef<NodeJS.Timeout | null>(null);
  const [currentDate, setCurrentDate] = useState<ValueInterface | null>(null);
  const { prices } = useAppSelector(selectUserData);
  const {
    value,
    value: { day, month, year },
    errors: { errorMessage },
  } = birthdayDateInput;

  const { trackFormErrorEvent } = useTracking();

  useEffect(() => {
    const currentBirthdate: ValueInterface = getInitialBirthday();
    setCurrentDate(currentBirthdate);
  }, []);

  useEffect(() => {
    if (Object.keys(value).length !== 0) {
      dispatch(updateBirthday());
      handleOfferChange({ year, month, day }, false);
    }
  }, []);

  useEffect(() => {
    return () => {
      clearExistingTimeout();
    };
  }, []);

  useEffect(() => {
    if (offerChangeParams !== null) {
      handleOfferChange({ year, month, day }, offerChangeParams.trackEvent);
      setCurrentDate({ year, month, day });
      setOfferChangeParams(null);
    }
  }, [offerChangeParams]);

  const clearExistingTimeout = () => {
    if (!!requestTimeout.current) clearTimeout(requestTimeout.current);
    requestTimeout.current = null;
  };

  const handleOfferChange = ({ year, month, day }: ValueInterface, trackEvent?: boolean) => {
    const requestData = { birthday: `${year}-${month}-${day}`, trackEvent };
    const gotBusinessId = storage.get(Keys.businessId);
    gotBusinessId ? dispatch(updateOffer(requestData)) : dispatch(getFees(requestData));
  };

  const isDateComplete = (newValue: ValueInterface) => {
    return (
      newValue?.day &&
      newValue?.month &&
      newValue?.year &&
      newValue?.day?.length === 2 &&
      newValue?.month?.length === 2 &&
      newValue?.year?.length >= 4
    );
  };
  const isSameBirthdate = (newValue: ValueInterface) => {
    return (
      newValue.year === currentDate?.year &&
      newValue.month === currentDate?.month &&
      newValue.day === currentDate?.day
    );
  };

  const scrollToPriceElement = () => {
    setTimeout(() => {
      if (window.screen.width < 768) {
        handleScrollToElement(ElementsToScrollEnum.MULTIPLE_CHOICE_CONTAINER);
      } else {
        handleScrollToElement(ElementsToScrollEnum.PRICE_BUTTON, 400);
      }
    }, 70);
  };

  const isEmptyValue = (value: ValueInterface) => !Object.keys(value).length;

  const handleClick = () => {
    clearExistingTimeout();

    if (isEmptyValue(value)) {
      const message = getTxt('bday.errors.badInput');
      setBirthdayDateInput({ value, errors: { valid: false, errorMessage: message } });
      return;
    }

    const error = isBirthdayWithError(value);
    if (error) return;

    const isSameDate = isSameBirthdate(value);
    if (isSameDate) {
      scrollToPriceElement();
      return;
    }

    const isComplete = isDateComplete(value);
    if (!isComplete) {
      trackFormErrorEvent(getTxt('bday.errors.badInput'));
      const message = getTxt('bday.errors.badInput');
      setBirthdayDateInput({ value, errors: { valid: !!error, errorMessage: message } });
      return;
    }

    dispatch(updateBirthday());
    setOfferChangeParams({ trackEvent: true });

    scrollToPriceElement();
  };

  const handleBirthday = (newValue: ValueInterface) => {
    const error = isBirthdayWithError(newValue);

    const isSameDate = isSameBirthdate(newValue);
    if (!isSameDate) {
      dispatch(updatePricesStatus(Loading.typing));
    }

    setBirthdayDateInput({ value: newValue, errors: { valid: !!error, errorMessage: error } });
  };

  const onEnterKeydown = (event: KeyboardEvent<HTMLSpanElement>): void => {
    if (event.key === 'Enter' && isDateComplete(value)) handleClick();
  };

  const scrollToElementOnBlur = () => {
    if (window.screen.width < 768) handleScrollToElement(ElementsToScrollEnum.BIRTHDAY_FORM);
  };

  const handleBlur = () => {
    const ONBLUR_ACTION_TIMEOUT = 200;
    clearExistingTimeout();
    const isSameDate = isSameBirthdate(value);

    if (isSameDate && prices.status === Loading.loaded) {
      scrollToElementOnBlur();
      return;
    }

    if (!errorMessage && isDateComplete({ year, month, day })) {
      dispatch(updateBirthday());
      scrollToElementOnBlur();
      requestTimeout.current = setTimeout(() => {
        setOfferChangeParams({ trackEvent: false });
      }, ONBLUR_ACTION_TIMEOUT);
    }
  };

  const isBirthdayWithError = (newValue: ValueInterface) => {
    if (!isDateComplete(newValue)) return;

    const parsedDate = asDate(newValue);
    const isValid = parsedDate instanceof Date && parsedDate.toString() !== 'Invalid Date';

    if (!isValid) {
      trackFormErrorEvent(getTxt('bday.errors.badInput'));
      return getTxt('bday.errors.badInput');
    }

    if (parsedDate.getFullYear() > today.getFullYear()) {
      trackFormErrorEvent(getTxt('bday.errors.inFuture'));
      return getTxt('bday.errors.inFuture');
    }

    if (minDate >= parsedDate) {
      trackFormErrorEvent(getTxt('bday.errors.above99'));
      return getTxt('bday.errors.above99');
    }
  };

  const identifier = `${parentId}_birthday`;

  return (
    <div id={identifier}>
      <div className='birthday__wrapper' id={`${identifier}_container-input`}>
        <ControlWithHint error={errorMessage} className='birthday__hint-control'>
          <DateInput
            id={`${identifier}_input`}
            value={value}
            onChange={handleBirthday}
            onKeyDown={onEnterKeydown}
            maxDate={today}
            minDate={minDate}
            className='izs'
            error={!!errorMessage}
            autoTab
            onBlur={handleBlur}
          />
        </ControlWithHint>
      </div>
      <p id={`${identifier}_input-description`}>{getTxt('bday.underInput')}</p>
      <Button id={DataLayerIds.IZS_Button_BeitragAnzeigen} onClick={handleClick}>
        {getTxt('bday.button')}
      </Button>
    </div>
  );
};
export default Birthday;
