import Modal from '@eg/elements/Modal';
import Button from '@eg/elements/Button';
import LayoutBox from '@eg/elements/LayoutBox';
import { useAppSelector, useAppDispatch } from 'hooks/hooks';
import { resetGeneralError, selectGeneralError } from 'stores/userDataStore';
import { IDefProps } from 'interfaces';
import getTxt from 'helpers/getTxt';

const ErrorModal: React.FC<IDefProps> = ({ parentId }) => {
  const dispatch = useAppDispatch();
  const isPopupOpened = useAppSelector(selectGeneralError);
  const handleDismiss = () => {
    dispatch(resetGeneralError());
    window.location.reload();
  };

  return (
    <Modal id={`${parentId}_modal_error`} data-testid={`${parentId}_modal`} open={isPopupOpened}>
      <h1>{getTxt('generalError.header')}</h1>
      <p>{getTxt('generalError.description')}</p>
      <LayoutBox horizontalAlignment='center' verticalAlignment='center'>
        <Button variant='text-link' size='auto' onClick={handleDismiss}>
          {getTxt('generalError.link')}
        </Button>
      </LayoutBox>
    </Modal>
  );
};

export default ErrorModal;
