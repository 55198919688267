import { useEffect } from 'react';
import Birthday from 'components/Birthday/Birthday';
import MultipleChoice from 'components/MultipleChoice/MultipleChoice';
import Price from 'components/Price/Price';
import CompareServicesModal from 'components/CompareServicesModal/CompareServicesModal';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import { useAppSelector } from 'hooks/hooks';
import cx from 'classnames';
import { trackPageLoaded } from 'dataLayerAPI/dataLayerEvents';
import ErrorModal from 'components/ErrorPage/ErrorModal';
import Banners from 'components/Banners/Banners';
import './index.scss';
import { userDataStoreInit, selectUserData } from 'stores/userDataStore';
import { useDispatch } from 'react-redux';

const App = () => {
  const dispatch = useDispatch();
  const {
    birthday,
    agencyDataOptions: { aktionsnummer },
  } = useAppSelector(selectUserData);
  const appClassNames = cx('izs-app-container', {
    'izs-app-container--sticky-header': birthday !== null,
  });
  const gridClassNames = cx('esc_container esc_container--l');
  const identifier = 'izs';

  useEffect(() => {
    dispatch(userDataStoreInit());
    trackPageLoaded(aktionsnummer);
  }, []);

  return (
    <div className={appClassNames} style={{ textAlign: 'center' }} id={`${identifier}_app`}>
      <div className={gridClassNames}>
        <Birthday parentId={identifier} />
        <MultipleChoice parentId={identifier} />
        <Price parentId={identifier} />
        <Banners parentId={identifier} />
        <CompareServicesModal parentId={identifier} />
        <StickyHeader parentId={identifier} />
        <ErrorModal parentId={identifier} />
      </div>
    </div>
  );
};

export default App;
