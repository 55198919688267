import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createSelector,
  ThunkAction,
  AnyAction,
} from '@reduxjs/toolkit';
import config from '../config/config';
import axios from 'axios';
import { trackApiError } from 'dataLayerAPI/dataLayerEvents';
import { RootState } from './store';
import { Keys, storage, FeesResponse, Loading, Options, GetOfferResponse } from 'helpers';
import {
  removePPZBusinessIdFromStorage,
  saveBusinessIdForIZSToStorage,
} from 'components/Birthday/birthday.helpers';
import { BirthdayDateInputInterface } from 'components/Birthday/birthday.types';
import {
  birthdateValueToFormat,
  formatBirthDateToValue,
  getInitialBirthday,
} from './userDataStore.helpers';

export interface MultiplieChoiceOptions {
  teeth: Options;
  dentures: Options;
}

interface CreateAgencyDataOptions {
  aktionsnummer?: string;
  vermittlernummer?: string;
  oeNummer?: string;
}

export interface Prices {
  status: Loading;
  values: Fees | null;
}
export interface userDataState {
  birthdayDateInput: BirthdayDateInputInterface;
  birthday: string | null;
  trackEvent: boolean;
  businessId: string | null;
  multiplieChoiceOptions: MultiplieChoiceOptions;
  agencyDataOptions: CreateAgencyDataOptions;
  prices: Prices;
  hasGeneralError: boolean;
  isMicrosite: boolean;
}

type QuellsystemType = 'ERGO_INTERNET' | 'ERGO_VERMITTLERHOMEPAGE';

export const urlParamTariff1 = 'zahnerhalt/',
  urlParamTariff2 = '/zahnersatz/';

const getActionNumberValueFromCookies = () =>
  window.document.cookie
    .match(`(^|;)\\s*${config.aktionNrCookie}\\s*=\\s*([^;]+)`)
    ?.pop()
    ?.split('%')[0] || null;

const getAgencyOptions = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const oeNummer = urlParams.get(config.adh_oenrKey) || undefined;
  const actionNumberFromCookie = getActionNumberValueFromCookies();
  let vermittlernummer, aktionsnummer;

  const appEventData = window[config.appEventDataKey] as Array<
    { event: string } & { [key: string]: any }
  >;

  const marketingLogicReady =
    appEventData && appEventData?.find((item) => item?.event === config.marketingLogicKey);

  // Is a SiteCore (ADHop) Page
  const actionNumberFromMarketing = marketingLogicReady
    ? marketingLogicReady?.miscellaneous?.marketing?.actionNumber
    : null;
  const isSiteCore = oeNummer && (actionNumberFromMarketing || actionNumberFromCookie);
  if (isSiteCore) {
    aktionsnummer = actionNumberFromMarketing || actionNumberFromCookie;
    return {
      oeNummer,
      vermittlernummer,
      aktionsnummer,
      isMicrosite: true,
    };
  }

  // Is a Microsite (Partners Kooperation) Page
  const agentNumberFromMarketing = marketingLogicReady
    ? marketingLogicReady?.miscellaneous?.marketing?.agentNumber
    : null;
  const isMicrosite = agentNumberFromMarketing && actionNumberFromMarketing;
  if (isMicrosite) {
    vermittlernummer = agentNumberFromMarketing;
    aktionsnummer = actionNumberFromMarketing;
    return {
      oeNummer,
      vermittlernummer,
      aktionsnummer,
      isMicrosite: true,
    };
  }

  // Is AEM Agents (ADHop) Page
  const agencyData = window[config.agencyDataKey];
  const isAEMAgentSite = agencyData && !!agencyData?.oenrNumber && actionNumberFromCookie;
  if (isAEMAgentSite) {
    return {
      oeNummer: agencyData.oenrNumber,
      aktionsnummer: actionNumberFromCookie,
      isMicrosite: true,
    };
  }

  // ERGO Public
  if (!isAEMAgentSite) {
    if (appEventData) {
      const marketingLogicReady = appEventData.find(
        (item) => item?.event === config.marketingLogicKey
      );
      if (marketingLogicReady) {
        vermittlernummer = marketingLogicReady?.miscellaneous?.marketing?.agentNumber;
        aktionsnummer =
          marketingLogicReady?.miscellaneous?.marketing?.actionNumber ||
          getActionNumberValueFromCookies();
      }
    } else {
      aktionsnummer = getActionNumberValueFromCookies();
    }
  }

  return {
    oeNummer,
    vermittlernummer,
    aktionsnummer,
    isMicrosite: false,
  };
};

const getOptionsFromUrl = () => {
  const [urlParamTariff1, teeth, urlParamTariff2, dentures] = window.location.hash
    .substr(1)
    .split('/');
  if (
    urlParamTariff1 == 'zahnerhalt' &&
    urlParamTariff2 == 'zahnersatz' &&
    !(teeth == 'None' && dentures == 'None')
  ) {
    const allowedValuesTeeth = ['KS', 'DVB', 'DVE'];
    const allowedValuesDentures = ['KS', 'DS75', 'DS90', 'DS100'];
    const teethOption = teeth == 'None' ? Options.KS : (teeth as Options);
    const denturesOption = dentures == 'None' ? Options.KS : (dentures as Options);

    return {
      teethUrl: allowedValuesTeeth.includes(teethOption) ? teethOption : undefined,
      denturesUrl: allowedValuesDentures.includes(denturesOption) ? denturesOption : undefined,
    };
  }
  return {
    teethUrl: undefined,
    denturesUrl: undefined,
  };
};

export const getMultiplieChoisesDefaultsData = () => {
  const { teethUrl, denturesUrl } = getOptionsFromUrl();
  return {
    teeth: teethUrl || Options.DVE,
    dentures: denturesUrl || Options.DS90,
  };
};
export interface Fees {
  [key: string]: {
    sum: string;
    sum6Months?: string;
  };
}

const { aktionsnummer, oeNummer, vermittlernummer, isMicrosite } = getAgencyOptions();
const agencyDataOptions = {
  aktionsnummer,
  oeNummer,
  vermittlernummer,
};

export const initialState: userDataState = {
  birthdayDateInput: {
    value: getInitialBirthday(),
    errors: {},
  },
  birthday: null,
  trackEvent: true,
  businessId: null,
  multiplieChoiceOptions: getMultiplieChoisesDefaultsData(),
  agencyDataOptions,
  prices: {
    status: Loading.neutral,
    values: {
      'KS/DS75': {
        sum: '4,70',
        sum6Months: '9,40',
      },
      'KS/DS90': {
        sum: '6,15',
        sum6Months: '12,30',
      },
      'KS/DS100': {
        sum: '8',
        sum6Months: '16',
      },
      'DVB/KS': {
        sum: '9,90',
      },
      'DVB/DS75': {
        sum: '14,60',
        sum6Months: '19,30',
      },
      'DVB/DS90': {
        sum: '16,05',
        sum6Months: '22,20',
      },
      'DVB/DS100': {
        sum: '17,90',
        sum6Months: '25,90',
      },
      'DVE/KS': {
        sum: '15,80',
      },
      'DVE/DS75': {
        sum: '20,50',
        sum6Months: '25,20',
      },
      'DVE/DS90': {
        sum: '21,95',
        sum6Months: '28,10',
      },
      'DVE/DS100': {
        sum: '23,80',
        sum6Months: '31,80',
      },
    },
  },
  hasGeneralError: false,
  isMicrosite,
};
export const getFees = createAsyncThunk<
  FeesResponse,
  { birthday: string; trackEvent?: boolean },
  { state: RootState }
>('userData/getFees', async ({ birthday, trackEvent = true }, { rejectWithValue, getState }) => {
  const url = `${config.apiUrl}/getfees`;
  try {
    const {
      userData: { agencyDataOptions, isMicrosite },
    } = getState();

    const selectedQuellsystem: QuellsystemType = isMicrosite
      ? 'ERGO_VERMITTLERHOMEPAGE'
      : 'ERGO_INTERNET';

    const response = await axios.post(url, {
      birthday: birthday,
      aktionsnummer: agencyDataOptions.aktionsnummer,
      vermittlernummer: agencyDataOptions.vermittlernummer,
      oeNummer: agencyDataOptions.oeNummer,
      selectedQuellsystem,
    });
    storage.set(Keys.businessId, response.data.businessId);
    return { ...response.data, trackEvent };
  } catch (error: any) {
    if (error?.message === 'Network Error') {
      trackApiError(url, error?.message, '');
    } else {
      trackApiError(url, error?.response?.status, error?.response?.statusText || error?.message);
    }
    throw rejectWithValue(error?.response?.status);
  }
});

export const updateOffer = createAsyncThunk<
  FeesResponse,
  { birthday: string; trackEvent?: boolean },
  { state: RootState }
>('userData/updateOffer', async ({ birthday, trackEvent = true }, { rejectWithValue }) => {
  const url = `${config.apiUrl}/updateOffer`;
  const businessId = storage.get(Keys.businessId);
  try {
    const response = await axios.post(url, {
      businessId,
      birthday,
    });
    return { ...response.data, trackEvent };
  } catch (error: any) {
    if (error?.message === 'Network Error') {
      trackApiError(url, error?.message, '');
    } else {
      trackApiError(url, error?.response?.status, error?.response?.statusText || error?.message);
    }
    throw rejectWithValue(error?.response?.status);
  }
});

export const getOffer = createAsyncThunk<
  GetOfferResponse,
  { businessId: string; trackEvent?: boolean },
  { state: RootState }
>('userData/getOffer', async ({ businessId, trackEvent = true }, { rejectWithValue }) => {
  const url = `${config.apiUrl}/getOffer/${businessId}`;
  try {
    const { data } = await axios.get<GetOfferResponse>(url);

    return { ...data, trackEvent };
  } catch (error: any) {
    if (error?.message === 'Network Error') {
      trackApiError(url, error?.message, '');
    } else {
      trackApiError(url, error?.response?.status, error?.response?.statusText || error?.message);
    }
    throw rejectWithValue(error?.response?.status);
  }
});

export const selectPriceStatus = (state: RootState) => state.userData.prices.status;

export const userData = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    updateChoice: (state, action: PayloadAction<{ id: string; type: string }>) => {
      const { type, id } = action.payload;
      state.multiplieChoiceOptions[type] = id;
    },
    updatePricesStatus: (state, action: PayloadAction<Loading>) => {
      state.prices.status = action.payload;
    },
    setGeneralError: (state) => {
      state.hasGeneralError = true;
    },
    resetGeneralError: (state) => {
      state.hasGeneralError = false;
    },
    updateBirthday: (state) => {
      state.birthday = birthdateValueToFormat(state.birthdayDateInput.value);
    },
    updateBirthdayDateInput: (state, action: PayloadAction<BirthdayDateInputInterface>) => {
      state.birthdayDateInput = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFees.fulfilled, (state, action: PayloadAction<FeesResponse>) => {
      state.prices.values = action.payload.fees;
      state.businessId = action.payload.businessId;
      state.trackEvent = action.payload.trackEvent;
      state.prices.status = Loading.loaded;
    });
    builder.addCase(getFees.pending, (state) => {
      state.prices.status = Loading.loading;
    });
    builder.addCase(getFees.rejected, (state, action: PayloadAction<any>) => {
      state.prices.status = Loading.error;
      if (action?.payload && action.payload.toString().startsWith('50')) {
        state.hasGeneralError = true;
      }
    });

    builder.addCase(updateOffer.fulfilled, (state, action: PayloadAction<FeesResponse>) => {
      state.prices.values = action.payload.fees;
      state.businessId = action.payload.businessId;
      state.trackEvent = action.payload.trackEvent;
      state.prices.status = Loading.loaded;
    });
    builder.addCase(updateOffer.pending, (state) => {
      state.prices.status = Loading.loading;
    });
    builder.addCase(updateOffer.rejected, (state, action: PayloadAction<any>) => {
      state.prices.status = Loading.error;
      if (action?.payload && action.payload.toString().startsWith('50')) {
        state.hasGeneralError = true;
      }
    });
    builder.addCase(getOffer.pending, (state) => {
      state.prices.status = Loading.loading;
    });
    builder.addCase(getOffer.fulfilled, (state, action: PayloadAction<GetOfferResponse>) => {
      state.prices.status = Loading.loaded;
      state.businessId = action.payload.businessId;
    });
    builder.addCase(getOffer.rejected, (state, action: PayloadAction<any>) => {
      state.prices.status = Loading.error;
      if (action?.payload && action.payload.toString().startsWith('50')) {
        state.hasGeneralError = true;
      }
    });
  },
});

export const selectGeneralError = (state: RootState) => state.userData.hasGeneralError;
export const selectMultipleOptions = (state: RootState) => state.userData.multiplieChoiceOptions;
export const selectProduct = createSelector(selectMultipleOptions, ({ teeth, dentures }) => {
  if (teeth && teeth !== 'KS' && dentures && dentures !== 'KS') return `${teeth}_${dentures}`;
  if (teeth && teeth !== 'KS') return teeth;
  if (dentures && dentures !== 'KS') return dentures;
});
export const selectBirthdayDateInput = (state: RootState) => state.userData.birthdayDateInput;

export const selectUserData = (state: RootState) => state.userData;

export const {
  updateChoice,
  updatePricesStatus,
  setGeneralError,
  resetGeneralError,
  updateBirthdayDateInput,
  updateBirthday,
} = userData.actions;

const getBirthDayFromOffer =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    const ppzBusinessIdInStorage = storage.get(Keys.ppzBusinessId);

    if (!ppzBusinessIdInStorage) {
      return;
    }

    const offer = await dispatch(getOffer({ businessId: ppzBusinessIdInStorage })).unwrap();

    const isBirthDateOfferValidToUse = !offer?.isSubmitted && !!offer?.birthDate;
    if (!isBirthDateOfferValidToUse) {
      removePPZBusinessIdFromStorage();
      return;
    }

    const newOfferWithPreviousBirthDateAssigned = await dispatch(
      getFees({ birthday: offer.birthDate })
    ).unwrap();

    removePPZBusinessIdFromStorage();
    saveBusinessIdForIZSToStorage(newOfferWithPreviousBirthDateAssigned.businessId);

    const offerBirthDateValue = formatBirthDateToValue(offer.birthDate);
    dispatch(
      updateBirthdayDateInput({
        errors: {},
        value: offerBirthDateValue,
      })
    );
    dispatch(updateBirthday());
  };

export const userDataStoreInit =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    storage.normalize();

    dispatch(getBirthDayFromOffer());
  };

export default userData.reducer;
