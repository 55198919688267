import { useEffect, useRef } from 'react';
import Radio from '@eg/elements/Radio';
import CheckColorIcon from '@eg/elements/Icons/CheckColorIcon';
import Button from '@eg/elements/Button';
import cx from 'classnames';
import Tooltip from 'components/Tooltip/Tooltip';
import getTxt from 'helpers/getTxt';
import { DataLayerIds, Options } from 'helpers/enums';
import { IDefProps } from 'interfaces';
import { popupOpen } from 'stores/uiStore';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { updateChoice } from 'stores/userDataStore';
import useTracking from 'dataLayerAPI/useTracking';
import './choicer.scss';

export interface ChoicesInterface {
  id: Options;
  text?: string;
  options?: Array<string>;
  eventTrackingId: DataLayerIds;
}

export interface DescriptionInterface {
  description: string;
  descriptionPopup: string;
  eventTrackingId: DataLayerIds;
}

export interface ChoiceEventTrackingIds {
  Zahnerzerhalt: DataLayerIds;
  keinSchutz: DataLayerIds;
  Dental_Vorsorge: DataLayerIds;
  Dental_Dental_VorsorgePremium: DataLayerIds;
}

export interface ChoicerInterface {
  choices: Array<ChoicesInterface>;
  descriptions: Array<DescriptionInterface>;
  type: string;
  id: string;
  compareServicesText: string;
}

const Descriptions = ({
  descriptions,
  type,
  choices,
}: {
  descriptions: DescriptionInterface[];
  type: string;
  choices: Array<ChoicesInterface>;
}) => {
  const { multiplieChoiceOptions } = useAppSelector(({ userData }) => userData);
  const choice = choices?.find(({ id }: { id: Options }) => id === multiplieChoiceOptions[type]);

  if (choice?.text) {
    return (
      <div className='choicer__description--no-data-wrapper'>
        <div
          className='choicer__description--no-data'
          dangerouslySetInnerHTML={{ __html: choice.text }}
        />
      </div>
    );
  }

  return (
    <>
      {descriptions.map(
        (
          { eventTrackingId, description, descriptionPopup }: DescriptionInterface,
          index: number
        ) => (
          <div key={index} className='choicer__description-item'>
            <div className='choicer__description-item-icon-text-wrapper'>
              <div className='choicer__description-item-icon-text'>
                <div>
                  <CheckColorIcon />
                </div>
                <div
                  className='choicer__description-item-text'
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
              <div className='choicer__description-tooltip' id={eventTrackingId}>
                <Tooltip
                  eventTrackingId={eventTrackingId}
                  descriptionPopup={descriptionPopup}
                  className='choicer__description-tooltip-text'
                />
              </div>
            </div>
            {choice?.options && (
              <div
                className='choicer__description-item-value'
                dangerouslySetInnerHTML={{ __html: choice.options[index] }}
              />
            )}
          </div>
        )
      )}
    </>
  );
};

const Choice = ({ choice, type }: { choice: ChoicesInterface; type: string }) => {
  const { id, eventTrackingId } = choice;
  const dispatch = useAppDispatch();
  const { trackClickEvent } = useTracking();
  const { multiplieChoiceOptions } = useAppSelector(({ userData }) => userData);
  const isMounted = useRef<boolean>(false);

  const isCurrentChoice = multiplieChoiceOptions[type] === id;
  // it's needed to grey out "Kein Schutz" option, when second choice is already "Kein Schutz"
  const [otherChoiceId] = Object.keys(multiplieChoiceOptions).filter((choice) => choice !== type);
  const isOtherChoiceKS = multiplieChoiceOptions[otherChoiceId] === Options.KS;

  const classNames = cx(
    'choicer__top',
    { 'choicer__top--active': isCurrentChoice },
    { 'choicer__top--disabled': isOtherChoiceKS && id === Options.KS }
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      isCurrentChoice && trackClickEvent(eventTrackingId, true);
    }
  }, [isCurrentChoice]);

  const handleChoiceClick = (e: React.MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget.dataset;
    if (isOtherChoiceKS && id === Options.KS) {
      return;
    }

    if (id) {
      dispatch(updateChoice({ id, type }));
    } else {
      throw Error('Choicer[handleChoiceClick]: missing id ');
    }
  };

  return (
    <div
      id={eventTrackingId}
      key={id}
      data-id={id}
      className={classNames}
      onClick={handleChoiceClick}>
      <div className='choicer__top-title'>{getTxt(`multipleChoice.${id}`)}</div>
      <div className='choicer__top-radio'>
        <Radio
          value={id}
          label=''
          checked={isCurrentChoice}
          disabled={isOtherChoiceKS && id === Options.KS}
        />
      </div>
    </div>
  );
};

const Choicer = (props: ChoicerInterface & IDefProps) => {
  const { choices, descriptions, type, id, compareServicesText, parentId } = props;
  const dispatch = useAppDispatch();
  const { trackClickEvent } = useTracking();

  const handleOpenModal = () => {
    trackClickEvent(id);
    dispatch(popupOpen(type));
  };

  const identifier = `${parentId}_${type}`;

  return (
    <div className='choicer__shadow'>
      <div className='choicer__wrapper'>
        {choices.map((choice: ChoicesInterface) => (
          <Choice choice={choice} type={type} key={choice.id} />
        ))}
      </div>
      <div className='choicer__description-wrapper' id={`${identifier}_description`}>
        <Descriptions descriptions={descriptions} type={type} choices={choices} />
      </div>
      <div className='choicer__modal' id={`${identifier}_compare-link`}>
        <Button
          id={id}
          className='choicer__button'
          variant='text-link'
          size='large'
          onClick={handleOpenModal}>
          {compareServicesText}
        </Button>
      </div>
    </div>
  );
};
export default Choicer;
