const config = {
  apiUrl:
    process.env.REACT_APP_BACKEND_URL ||
    (window as any).CONFIG_BACKEND_URL ||
    'https://zahn-ppz-izs-dvit.aws-0313.cloudfirst.digital/backend',
  otrUrlRedirect:
    process.env.REACT_APP_OTR_URL ||
    (window as any).CONFIG_OTR_URL ||
    'https://zahn-ppz-otr-dvit.aws-0378.cloudfirst.digital',
  productTableKeyTeeth: 'zahnerhaltLeistungen',
  productTableKeyDentures: 'zahnersatzLeistungen',
  buildDate: process.env.BUILD_DATE,
  otrRedirectKey: 'otrRedirect',
  aemAppId: 'zahn-izs',
  otrInitialRoute: 'initial-selection',
  adh_oenrKey: 'adh_oenr',
  marketingLogicKey: 'MarketingLogicReady',
  aktionNrCookie: 'aktionNr',
  aemOENummerKey: 'oeNummer',
  agencyDataKey: 'agencyData',
  appEventDataKey: 'appEventData',
  pageName: 'pageName',
  primaryCategory: 'primaryCategory',
  productName: 'productName',
  aemEkomiEnabled: 'ekomiEnabled',
  aemEkomiSubtext: 'ekomiSubtext',
  aemWarrantyEnabled: 'warrantyEnabled',
  birthdayMaxNumOfDaysToExpireData: Number(
    process.env.REACT_APP_BIRTHDAY_MAX_NUM_OF_DAYS_TO_EXPIRE_DATA
  ),
};

export default config;
