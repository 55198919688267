import { Keys, storage } from 'helpers';
import { ValueInterface } from './birthday.types';

export const saveBirthdayToStorage = ({ year, month, day }: ValueInterface) => {
  storage.set(
    Keys.izsBirthdate,
    JSON.stringify({ birthday: `${year}-${month}-${day}`, date: new Date() })
  );
};

export const removeBirthdayFromStorage = () => {
  storage.remove(Keys.izsBirthdate);
};

export const saveBusinessIdForIZSToStorage = (businessId: string) => {
  storage.set(Keys.businessId, businessId);
};

export const removePPZBusinessIdFromStorage = () => {
  storage.remove(Keys.ppzBusinessId);
};
