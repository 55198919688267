import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { selectBirthdayDateInput, updateBirthdayDateInput } from 'stores/userDataStore';
import { BirthdayDateInputInterface } from './birthday.types';

type UseBirthday = () => {
  birthdayDateInput: BirthdayDateInputInterface;
  setBirthdayDateInput: (birthdayDateInput: BirthdayDateInputInterface) => {
    payload: BirthdayDateInputInterface;
    type: 'userData/updateBirthdayDateInput';
  };
};

export const useBirthday: UseBirthday = () => {
  const dispatch = useAppDispatch();

  const birthdayDateInput = useAppSelector(selectBirthdayDateInput);
  const setBirthdayDateInput = (birthdayDateInput: BirthdayDateInputInterface) =>
    dispatch(updateBirthdayDateInput(birthdayDateInput));

  return { birthdayDateInput, setBirthdayDateInput };
};
