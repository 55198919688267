import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'stores/store';
import queryString from 'query-string';

export interface uiState {
  isPopupOpened: boolean;
  urlVariables: any;
  choiceType?: string;
}

const urlVariables = queryString.parse(window.location.search);

const initialState: uiState = {
  isPopupOpened: false,
  urlVariables: { ...urlVariables },
  choiceType: undefined,
};

export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    popupOpen: (state, action) => {
      state.isPopupOpened = true;
      state.choiceType = action.payload;
    },
    popupClose: (state) => {
      state.isPopupOpened = false;
      state.choiceType = undefined;
    },
  },
});

export const { popupOpen, popupClose } = ui.actions;

export const selectPopup = (state: RootState) => state.ui.isPopupOpened;
export const selectChoiceType = (state: RootState) => state.ui.choiceType;

export default ui.reducer;
