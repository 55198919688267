import { createListenerMiddleware } from '@reduxjs/toolkit';
import {
  removeBirthdayFromStorage,
  saveBirthdayToStorage,
} from 'components/Birthday/birthday.helpers';
import { isValidBirthdayDateInput } from 'stores/userDataStore.helpers';
import { updateBirthdayDateInput } from '../userDataStore';

export const storageMiddleware = createListenerMiddleware();
storageMiddleware.startListening({
  actionCreator: updateBirthdayDateInput,
  effect: async (action) => {
    if (isValidBirthdayDateInput(action.payload)) {
      saveBirthdayToStorage(action.payload.value);
    } else {
      removeBirthdayFromStorage();
    }
  },
});
