import { useState } from 'react';
import UnmanagedTooltipIcon from '@eg/elements/UnmanagedTooltipIcon';
import useTracking from 'dataLayerAPI/useTracking';
import { DataLayerIds } from 'helpers/enums';

const Tooltip = ({
  eventTrackingId,
  descriptionPopup,
  className,
}: {
  eventTrackingId: DataLayerIds;
  descriptionPopup: string;
  className?: string;
}) => {
  const { trackClickEvent } = useTracking();
  const [isOpen, toogleOpen] = useState(false);
  const toogleModal = (id: string) => {
    !isOpen && trackClickEvent(id);
    toogleOpen((state) => !state);
  };
  return (
    <UnmanagedTooltipIcon isModal onToggleOpen={() => toogleModal(eventTrackingId)} isOpen={isOpen}>
      <div className={className} dangerouslySetInnerHTML={{ __html: descriptionPopup }} />
    </UnmanagedTooltipIcon>
  );
};

export default Tooltip;
