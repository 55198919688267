import { useEffect } from 'react';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { MultiplieChoiceOptions, Prices, selectUserData } from 'stores/userDataStore';
import { useAppSelector } from 'hooks/hooks';
import getTxt from 'helpers/getTxt';
import { Options, Loading, DataLayerIds } from 'helpers/enums';
import useTracking from 'dataLayerAPI/useTracking';
import { PRICE_BASE_CLASS } from './Price.const';

export const getChoiceSelectionsText = (teeth: Options, dentures: Options) => {
  if (teeth === Options.KS) return getTxt(`multipleChoice.${dentures}`);
  if (dentures === Options.KS) return getTxt(`multipleChoice.${teeth}`);

  return `${getTxt(`multipleChoice.${teeth}`)} + ${getTxt(`multipleChoice.${dentures}`)}`;
};

const numberFormatter = (value?: string) =>
  value ? parseFloat(value.replace(',', '.')).toFixed(2).replace('.', ',') : '--,--';

export const getPriceValue = (
  isStartingPrice: boolean,
  { teeth, dentures }: MultiplieChoiceOptions,
  { status, values }: Prices
) => {
  if (status === Loading.typing) {
    return '--,--';
  }
  if (!!values) {
    const value = values[`${teeth}/${dentures}`];
    return numberFormatter(isStartingPrice ? value?.sum : value?.sum6Months);
  }
};

export const PriceDetails: React.FC = () => {
  const { trackClickEvent } = useTracking();
  const { multiplieChoiceOptions, birthday, prices } = useAppSelector(selectUserData);

  useEffect(() => {
    if (prices.status === Loading.loaded) {
      trackClickEvent(DataLayerIds.IZS_Button_BeitragAnzeigen, true);
    }
  }, [prices.values]);

  if (prices.status === Loading.loading) {
    return (
      <div className={`${PRICE_BASE_CLASS}__spinner-wrapper`}>
        <LoadingSpinner viewport='relative' show={true} />
      </div>
    );
  }
  if (!!prices.values) {
    return (
      <>
        {birthday === null && (
          <span className={`${PRICE_BASE_CLASS}__zb`}>{getTxt('price.zb')}</span>
        )}
        <span className={`${PRICE_BASE_CLASS}__value`}>
          {getPriceValue(true, multiplieChoiceOptions, prices)}
          &nbsp;&euro;
        </span>
        <span className={`${PRICE_BASE_CLASS}__mtl`}>{getTxt('price.mtl')}</span>
        {multiplieChoiceOptions.dentures !== Options.KS && (
          <span className={`${PRICE_BASE_CLASS}__7-months`}>
            {getTxt('price.from')} {getTxt('price.fromMonth')}
            &nbsp;
            {birthday === null && <>z. B.&nbsp;</>}
            je {getPriceValue(false, multiplieChoiceOptions, prices)}
            &nbsp;&euro;*
          </span>
        )}
      </>
    );
  }

  return null;
};
