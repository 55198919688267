import cx from 'classnames';
import Tooltip from 'components/Tooltip/Tooltip';
import { DataLayerIds } from 'helpers/enums';
import './trustElement.scss';

type Data = {
  imgUrl?: string;
  header?: string;
  description?: string;
  tooltipText?: string;
};
const TrustElement = ({
  id,
  trackingId,
  data,
  isWarranty = false,
  hasThreeColumns,
}: {
  id: string;
  trackingId: DataLayerIds;
  data: Data;
  hasThreeColumns: boolean;
  isWarranty?: boolean;
}) => {
  const { imgUrl, header, description, tooltipText } = data;
  const isBannerHidden = !imgUrl && !header && !description && !tooltipText;
  if (isBannerHidden) {
    return null;
  }

  const trustImgClass = cx({
    'izs_banner_trust-img': !isWarranty,
    'izs_banner_warranty-img': isWarranty,
  });

  const textContainerClass = cx({
    'izs_trust-banner_text-content': !hasThreeColumns,
  });

  const elementContainerClass = cx('izs_trust-banner_element-container', {
    'izs_trust-banner_element-container__three-columns': hasThreeColumns,
  });

  return (
    <div className={elementContainerClass} id={id}>
      {imgUrl && <img src={imgUrl} alt='Stiftung' className={trustImgClass} />}
      <div className={textContainerClass}>
        {description && <p className='izs_trust-banner_description'>{description}</p>}
        {header && <p className='izs_trust-banner_header'>{header}</p>}
        {tooltipText && <Tooltip eventTrackingId={trackingId} descriptionPopup={tooltipText} />}
      </div>
    </div>
  );
};

export default TrustElement;
