import config from 'config/config';
import { getAemKeyValue } from 'helpers/getAemKeyValue';
import { getMultiplieChoisesDefaultsData } from 'stores/userDataStore';

export type IZS_TrackableEvent = MouseEvent | React.FocusEvent<HTMLDivElement>;

export type TrackingData = {
  productCombination: string;
  businessId: string | null;
  calculatedValue: string | null;
  calculatedValueReduced: string | null;
  pageName: string;
  primaryCategory: string;
  productName: string;
  birthdayYear: string | null;
  oeNumber?: string;
  conversion: {
    conversionType?: string;
  };
};

export const trackApiError = (url: string, type: string, message: string) => {
  window['appEventData'].push(apiErrorEvent(url, type, message));
};

const defaultTrackingPageParams = {
  pageName: 'ProdEinstieg',
  primaryCategory: 'ZahnZusatz',
  productName: 'izs',
};

type PageTracking = {
  pageName: string;
  primaryCategory: string;
  productName: string;
};

export const getParamsFromAEM = (): PageTracking => {
  const data = getAemKeyValue('pageName', 'primaryCategory', 'productName');
  if (Object.keys(data).length > 0) {
    return data as PageTracking;
  }
  return defaultTrackingPageParams;
};

export const trackPageLoaded = (oeNumber?: string) => {
  window['appEventData'] = window['appEventData'] || [];
  const { teeth, dentures } = getMultiplieChoisesDefaultsData();
  const data = {
    productCombination: `${teeth}_${dentures}`,
    businessId: null,
    calculatedValue: null,
    calculatedValueReduced: null,
    conversion: {},
    ...getParamsFromAEM(),
    birthdayYear: null,
    oeNumber,
  };
  window['appEventData'].push(pageLoadEventObject(data));
};

export function getSharedEventObject({
  productCombination,
  businessId,
  calculatedValue,
  pageName,
  primaryCategory,
  productName,
  calculatedValueReduced,
  conversion,
}: TrackingData) {
  return {
    page: {
      attributes: {
        displayedBrand: 'Ergo',
        brokerModus:
          'Off' /* Feedback from buisness, for now this value is set to off by default, when App will be deployed on Broker pages, this should be set based on given flag */,
      },
      pageInfo: {
        issueDate: `izs2022|${config.buildDate}`,
        pageName: `izs:${pageName}`,
        sysEnv: 'dev1',
      },
      category: {
        pagePurpose: 'Sale',
        primaryCategory: 'izs2022',
      },
    },
    product: [
      {
        productInfo: {
          productName,
        },
        attributes: {
          productCombination,
        },
        category: {
          primaryCategory,
        },
      },
    ],
    transaction: {
      transactionID: businessId,
      attributes: {
        ...conversion,
        calculatedValue,
        calculatedValueReduced,
      },
    },
  };
}

export function pageLoadEventObject(data: TrackingData) {
  return {
    event: 'PageLoaded',
    eventdetails: {
      timedCall: true,
    },
    ...getSharedEventObject(data),
  };
}

const nonTimedCallEventsIds = ['IZS_Button_JetztOnlineAbschließen'];

export function generalClickEvent(id: string, data: TrackingData) {
  return {
    event: 'GeneralClick',
    eventdetails: {
      clickedElement: id,
      clickType: 'other',
      timedCall: nonTimedCallEventsIds.includes(id) ? false : true,
    },
    miscellaneous: {
      agentSwitch: {
        oeNumber: data.oeNumber || '002580237',
      },
    },
    user: [
      {
        profile: {
          attributes: {
            birthday: data.birthdayYear,
          },
        },
      },
    ],
    ...getSharedEventObject(data),
  };
}

export function formFieldErrorEvent(message: string, data: TrackingData) {
  return {
    event: 'GeneralClick',
    eventdetails: {
      clickType: 'other',
      timedCall: true,
    },
    miscellaneous: {
      errors: {
        errorFlag: true,
        errorType: 'FormFieldError',
        errorMessages: message,
        errorUrl: window.location.href,
      },
    },
    ...getSharedEventObject(data),
  };
}

export function apiErrorEvent(url: string, type: string, message: string) {
  return {
    event: 'GeneralClick',
    eventdetails: {
      clickType: 'other',
    },
    miscellaneous: {
      errors: {
        errorFlag: true,
        errorType: type,
        errorMessages: message,
        errorUrl: url,
      },
    },
  };
}
