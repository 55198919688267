export enum Loading {
  // a state when nothing has been initialized yet
  neutral = 'NEUTRAL',
  loading = 'LOADING',
  loaded = 'LOADED',
  error = 'ERROR',
  typing = 'TYPING',
}

export enum Options {
  KS = 'KS',
  DVB = 'DVB',
  DVE = 'DVE',
  DS75 = 'DS75',
  DS90 = 'DS90',
  DS100 = 'DS100',
}

export enum ChoiceTypes {
  teeth = 'teeth',
  dentures = 'dentures',
}

export const enum DataLayerIds {
  IZS_Button_BeitragAnzeigen = 'IZS_Button_BeitragAnzeigen',
  IZS_Tab_Zahnerhalt_keinSchutz = 'IZS_Tab_Zahnerhalt_keinSchutz',
  IZS_Tab_Zahnerhalt_Dental_Vorsorge = 'IZS_Tab_Zahnerhalt_Dental_Vorsorge',
  IZS_Tab_Zahnerhalt_Dental_Dental_VorsorgePremium = 'IZS_Tab_Zahnerhalt_Dental_VorsorgePremium',
  IZS_Tab_Zahnersatz_keinSchutz = 'IZS_Tab_Zahnersatz_keinSchutz',
  IZS_Tab_Zahnersatz_DentalSchutz75 = 'IZS_Tab_Zahnersatz_DentalSchutz75',
  IZS_Tab_Zahnersatz_DentalSchutz90 = 'IZS_Tab_Zahnersatz_DentalSchutz90',
  IZS_Tab_Zahnersatz_DentalSchutz100 = 'IZS_Tab_Zahnersatz_DentalSchutz100',
  IZS_InfoIcon_Prophylaxe = 'IZS_InfoIcon_Prophylaxe',
  IZS_InfoIcon_Kunststofffuellung = 'IZS_InfoIcon_Kunststofffuellung',
  IZS_InfoIcon_WurzelParodontose = 'IZS_InfoIcon_WurzelParodontose',
  IZS_InfoIcon_Implantate = 'IZS_InfoIcon_Implantate',
  IZS_InfoIcon_BrueckenKronen = 'IZS_InfoIcon_BrueckenKronen',
  IZS_InfoIcon_InlaysOnlays = 'IZS_InfoIcon_InlaysOnlays',
  IZS_Link_ZahnerhaltLeistungenVergleichen = 'IZS_Link_ZahnerhaltLeistungenVergleichen',
  IZS_Link_ZahnersatzLeistungenVergleichen = 'IZS_Link_ZahnersatzLeistungenVergleichen',
  IZS_Link_GeburtsdatumEingeben = 'IZS_Link_GeburtsdatumEingeben',
  IZS_Icon_GeburtsdatumAendern = 'IZS_Icon_GeburtsdatumAendern',
  IZS_Button_JetztOnlineAbschließen = 'IZS_Button_JetztOnlineAbschließen',
  IZS_InfoIcon_Zahnerhalt = 'IZS_InfoIcon_Zahnerhalt',
  IZS_InfoIcon_Zahnersatz = 'IZS_InfoIcon_Zahnersatz',
  IZS_InfoIcon_TrustElement1 = 'IZS_InfoIcon_TrustElement1',
  IZS_InfoIcon_TrustElement2 = 'IZS_InfoIcon_TrustElement2',
  IZS_InfoIcon_TrustElement3 = 'IZS_InfoIcon_TrustElement3',
}

export enum FEES_TYPES {
  KSDS75 = 'KS/DS75',
  KSDS90 = 'KS/DS90',
  KSDS100 = 'KS/DS100',
  DVEKS = 'DVE/KS',
  DVEDS75 = 'DVE/DS75',
  DVEDS90 = 'DVE/DS90',
  DVEDS100 = 'DVE/DS100',
  DVBKS = 'DVB/KS',
  DVBDS75 = 'DVB/DS75',
  DVBDS90 = 'DVB/DS90',
  DVBDS100 = 'DVB/DS100',
}

export type CalculatedFeeValuesType = Record<FEES_TYPES, { sum: string; sum6Months?: string }>;

export type FeesResponse = {
  fees: CalculatedFeeValuesType;
  businessId: string;
  trackEvent: boolean;
};

export type GetOfferResponse = {
  businessId: string;
  birthDate: string;
  isSubmitted: boolean;
};
