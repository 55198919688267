import { DataLayerIds, Options } from 'helpers/enums';
import { useAppSelector } from 'hooks/hooks';
import { useCallback, useRef } from 'react';
import { Fees, selectProduct, selectUserData } from 'stores/userDataStore';
import { formFieldErrorEvent, generalClickEvent, getParamsFromAEM } from './dataLayerEvents';

const getCalculatedValues = (values: Fees | null, teeth: Options, dentures: Options) => {
  if (!values) {
    return [null, null];
  }
  const priceFirst6Moths = Number(values[`${teeth}/${dentures}`].sum) * 6;
  const priceNext6Months = values[`${teeth}/${dentures}`]?.sum6Months
    ? Number(values[`${teeth}/${dentures}`].sum6Months) * 6
    : priceFirst6Moths;

  return [
    (priceNext6Months * 2).toLocaleString('en-GB', {
      maximumFractionDigits: 2,
    }),
    (priceFirst6Moths + priceNext6Months).toLocaleString('en-GB', {
      maximumFractionDigits: 2,
    }),
  ];
};

const useTracking = () => {
  const {
    birthday,
    businessId,
    multiplieChoiceOptions: { teeth, dentures },
    prices,
    trackEvent,
    agencyDataOptions: { oeNummer },
  } = useAppSelector(selectUserData);
  const product = useAppSelector(selectProduct);
  const birthdayYear = birthday ? new Date(birthday).getFullYear().toString() : null;
  const aemData = getParamsFromAEM();
  const oldPrice = useRef<string | null>(null);
  const [price, priceReduced] = getCalculatedValues(prices.values, teeth, dentures);

  let conversion: { conversionType?: string } = {};

  if (oldPrice.current !== price) {
    conversion.conversionType = 'IzsCalculation';
    oldPrice.current = price;
  } else {
    conversion = {};
  }

  const getStoreData = useCallback(
    (priceChanged?: boolean) => ({
      productCombination: product || '',
      businessId,
      calculatedValue: price,
      calculatedValueReduced: priceReduced,
      ...aemData,
      birthdayYear,
      oeNumber: oeNummer,
      conversion: priceChanged ? { conversionType: 'IzsCalculation' } : {},
    }),
    [teeth, dentures, prices]
  );

  const trackFormErrorEvent = (message: string) => {
    if (window['appEventData']) {
      window['appEventData'].push(formFieldErrorEvent(message, getStoreData()));
    }
  };

  const trackClickEvent = (targetId: string, priceChanged?: boolean) => {
    const isBeitragAnzeigenEvent = targetId === DataLayerIds.IZS_Button_BeitragAnzeigen;
    if (!isBeitragAnzeigenEvent || (trackEvent && isBeitragAnzeigenEvent)) {
      window['appEventData'].push(generalClickEvent(targetId, getStoreData(priceChanged)));
    }
  };

  return { trackClickEvent, trackFormErrorEvent };
};

export default useTracking;
