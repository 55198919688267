import cx from 'classnames';
import { EKomiRating } from '@eg/elements/EKomiRating';
import { useEffect, useState } from 'react';
import axios from 'axios';
import config from 'config/config';
import './ekomiElement.scss';

const EkomiElement = ({
  additionalSubtext,
  hasThreeColumns,
  id,
}: {
  id: string;
  additionalSubtext: string;
  hasThreeColumns: boolean;
}) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<{ averageRating: number; totalRatings: number } | undefined>();

  useEffect(() => {
    const getData = () => {
      axios
        .get(`${config.apiUrl}/getekomiratings`)
        .then(({ data }) => {
          setData(data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getData();
  }, []);

  const ekomiClass = cx('izs_ekomi_container', {
    'izs_ekomi_container__three-columns': hasThreeColumns,
  });
  const align = hasThreeColumns ? 'left' : 'center';
  return (
    <div className={ekomiClass} id={id}>
      {!isLoading && data && (
        <EKomiRating
          rating={data.averageRating}
          totalRatings={data.totalRatings}
          variant='with-seal'
          additionalSubtext={additionalSubtext}
          align={align}
        />
      )}
    </div>
  );
};

export default EkomiElement;
