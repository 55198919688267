import { DataLayerIds, Options } from 'helpers/enums';
import getTxt from 'helpers/getTxt';

export const choicer1Data = [
  {
    id: Options.KS,
    text: getTxt('multipleChoice.noOptionTeeth'),
    eventTrackingId: DataLayerIds.IZS_Tab_Zahnerhalt_keinSchutz,
  },
  {
    id: Options.DVB,
    options: [`${getTxt('multipleChoice.1InsuranceYear')}`, '100%', '100%'],
    eventTrackingId: DataLayerIds.IZS_Tab_Zahnerhalt_Dental_Vorsorge,
  },
  {
    id: Options.DVE,
    options: [`${getTxt('multipleChoice.unlimited')}`, '100%', '100%'],
    eventTrackingId: DataLayerIds.IZS_Tab_Zahnerhalt_Dental_Dental_VorsorgePremium,
  },
];

export const choicer1Descriptions = [
  {
    description: getTxt('multipleChoice.prophylactic'),
    descriptionPopup: getTxt('multipleChoice.prophylacticInfo'),
    eventTrackingId: DataLayerIds.IZS_InfoIcon_Prophylaxe,
  },
  {
    description: getTxt('multipleChoice.fillings'),
    descriptionPopup: getTxt('multipleChoice.fillingsInfo'),
    eventTrackingId: DataLayerIds.IZS_InfoIcon_Kunststofffuellung,
  },
  {
    description: getTxt('multipleChoice.mucosalTreatment'),
    descriptionPopup: getTxt('multipleChoice.mucosalTreatmentInfo'),
    eventTrackingId: DataLayerIds.IZS_InfoIcon_WurzelParodontose,
  },
];

export const choicer2Data = [
  {
    id: Options.KS,
    text: getTxt('multipleChoice.noOptionDentures'),
    eventTrackingId: DataLayerIds.IZS_Tab_Zahnersatz_keinSchutz,
  },
  {
    id: Options.DS75,
    options: ['75%', '75%', '75%'],
    eventTrackingId: DataLayerIds.IZS_Tab_Zahnersatz_DentalSchutz75,
  },
  {
    id: Options.DS90,
    options: ['90%', '90%', '90%'],
    eventTrackingId: DataLayerIds.IZS_Tab_Zahnersatz_DentalSchutz90,
  },
  {
    id: Options.DS100,
    options: ['100%', '100%', '100%'],
    eventTrackingId: DataLayerIds.IZS_Tab_Zahnersatz_DentalSchutz100,
  },
];

export const choicer2Descriptions = [
  {
    description: getTxt('multipleChoice.implants'),
    descriptionPopup: getTxt('multipleChoice.implantsInfo'),
    eventTrackingId: DataLayerIds.IZS_InfoIcon_Implantate,
  },
  {
    description: getTxt('multipleChoice.crowns'),
    descriptionPopup: getTxt('multipleChoice.crownsInfo'),
    eventTrackingId: DataLayerIds.IZS_InfoIcon_BrueckenKronen,
  },
  {
    description: getTxt('multipleChoice.inlays'),
    descriptionPopup: getTxt('multipleChoice.inlaysInfo'),
    eventTrackingId: DataLayerIds.IZS_InfoIcon_InlaysOnlays,
  },
];
