export enum ElementsToScrollEnum {
  BIRTHDAY_FORM = '#izs_birthday_input',
  PRICE_BUTTON = '#izs_price',
  MULTIPLE_CHOICE_CONTAINER = '#izs_multiple-choice_container',
}

export const handleScrollToElement = (elementsSelector: ElementsToScrollEnum, extraOffset = 0) => {
  const elementToScroll = document.querySelector<HTMLElement>(elementsSelector);
  if (elementToScroll !== null) {
    const { offsetTop } = elementToScroll;
    const navTopHeight = window.document.querySelector<HTMLElement>('#MainNavi')?.offsetHeight ?? 0;
    const stickyHeaderHeight =
      window.document.querySelector<HTMLElement>('#izs_mobileHeader')?.offsetHeight ?? 0;
    window.scroll({
      top: offsetTop - navTopHeight - stickyHeaderHeight - extraOffset,
      behavior: 'smooth',
    });
  }
};
