import { AemWindow } from 'helpers/interfaces';
import { useAppSelector } from 'hooks/hooks';
import { selectProduct } from 'stores/userDataStore';
import EkomiElement from './EkomiElement';
import TrustElement from './TrustElement';
import config from 'config/config';
import { DataLayerIds } from 'helpers/enums';
import { IDefProps } from 'interfaces';
import './banners.scss';

function getTrustProductParams(params: { [key: string]: string }, product?: string) {
  const imagePath = params?.[`${product}_trustImage`];
  const description1 = params?.[`${product}_trustDescription1`];
  const description2 = params?.[`${product}_trustDescription2`];
  const tooltipText = params?.[`${product}_trustTooltip`];

  if (!imagePath && !description1 && !description2 && !tooltipText) {
    return undefined;
  }

  return {
    imgUrl: imagePath ? `${window.location.origin}${imagePath}` : undefined,
    description: description1,
    header: description2,
    tooltipText,
  };
}
function getWarrantyProductParams(params: { [key: string]: string }) {
  return {
    imgUrl: params?.warrantyIcon ? `${window.location.origin}${params.warrantyIcon}` : undefined,
    header: params?.warrantyHeader,
    description: params?.warrantyDescription,
    tooltipText: params?.warrantyTooltip,
  };
}

const Banners: React.FC<IDefProps> = ({ parentId }) => {
  const product = useAppSelector(selectProduct);
  const { params } = window as AemWindow;
  const bannerAppKey = params && Object.keys(params).find((k) => k.includes(config.aemAppId));
  const trustAppParams = bannerAppKey ? params?.[bannerAppKey] : undefined;
  if (!trustAppParams) {
    return null;
  }
  const trustData = getTrustProductParams(trustAppParams, product);
  const warrantyEnabled = trustAppParams[config.aemWarrantyEnabled] === 'true';
  const warrantyData = warrantyEnabled ? getWarrantyProductParams(trustAppParams) : undefined;
  const ekomiEnabled = trustAppParams[config.aemEkomiEnabled] === 'true';
  const ekomiSubtext = trustAppParams[config.aemEkomiSubtext];

  const hasThreeColumns = !!(trustData && warrantyData && ekomiEnabled);
  return (
    <>
      {trustAppParams && (
        <div className='izs_banners_container' id={`${parentId}_banner`}>
          {trustData && (
            <TrustElement
              data={trustData}
              hasThreeColumns={hasThreeColumns}
              id={`${parentId}_banner_trust`}
              trackingId={DataLayerIds.IZS_InfoIcon_TrustElement1}
            />
          )}
          {ekomiEnabled && (
            <EkomiElement
              additionalSubtext={ekomiSubtext}
              hasThreeColumns={hasThreeColumns}
              id={`${parentId}_banner_ekomi`}
            />
          )}
          {warrantyData && (
            <TrustElement
              data={warrantyData}
              isWarranty={true}
              hasThreeColumns={hasThreeColumns}
              id={`${parentId}_banner_warranty`}
              trackingId={DataLayerIds.IZS_InfoIcon_TrustElement3}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Banners;
