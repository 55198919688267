import config from 'config/config';
import { AemWindow } from './interfaces';

type AemParamsKeys = keyof typeof config;

export const getAemKeyValue = (...keys: AemParamsKeys[]): Record<string, string> => {
  const { params } = window as AemWindow;
  const appId = params && Object.keys(params).filter((k) => k.includes(config.aemAppId))[0];
  return keys.reduce((acc, key) => {
    acc[key] = appId ? params[appId][config[key]] : undefined;
    return acc;
  }, {});
};
